import React, { useEffect, useState } from 'react'
import axios from 'axios';
import CardAlarm from './CardAlarm';
import LiveProduction from './LiveProduction';
import Preventive from './Preventive';
import TargetSummary from './TargetSummary';

const Production = (props) => {
  return (
    <>
      <div className='row card_alarm_style'>
        <CardAlarm />
        <div className='col-lg-12 col-md-12 col-sm-12' style={{ marginTop: "30px" }}>
          <LiveProduction lastData={props.lastData} />
        </div>
        {/* <div className='col-lg-4 col-md-4 col-sm-12'>
                    <CardAlarm count_danger={count_danger} count_warn={count_warn} count_safe={count_safe} />
                </div> */}
      </div>
      <div className='row'>
        <TargetSummary />
        <Preventive />
      </div>
    </>
  )
}

export default Production