import React from 'react'
import TotalProduction from '../../Charts/TotalProduction'
import LiveProduction from '../Production/LiveProduction'
import MachineList from './MachineList'

const AssemblyLine = (props) => {
  return (
    <>
      <div className='row' style={{marginTop:"20px"}}>
        <div className='col-lg-8 col-md-8 col-sm-8'>
          <LiveProduction lastData={props.lastData} />
        </div>
        <div className='col-lg-4 col-md-4 col-sm-4'>
          <TotalProduction />
        </div>
      </div>
      <div className='row'>
      <div className='col-lg-12 col-md-12 col-sm-12'>
          <MachineList />
        </div>
      </div>
    </>
  )
}

export default AssemblyLine