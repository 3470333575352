import React from 'react'
import Production from '../../Images/prod.png'

const LiveProduction = (props) => {
  return (
    <>
      <div className='card'>
        <div className='card-inner'>
          <div className='title'>
            <h4>Live Production</h4>
          </div>
          <div className="live-prod-image">
            <div className={`${props.lastData.value1 == 2 ? 'status-danger dot1' : props.lastData.value1 == 1  ? 'status-warning dot1' : props.lastData.value1 == 0  ? 'status-safe dot1': ""}`}></div>
            <div className={`${props.lastData.value2 == 2 ? 'status-danger dot2' : props.lastData.value2 == 1  ? 'status-warning dot2' : props.lastData.value2 == 0  ? 'status-safe dot2':""}`}></div>
            <div className={`${props.lastData.value3 == 2 ? 'status-danger dot3' : props.lastData.value3 == 1  ? 'status-warning dot3' : props.lastData.value3 == 0  ? 'status-safe dot3':""}`}></div>
            <div className={`${props.lastData.value4 == 2 ? 'status-danger dot4' : props.lastData.value4 == 1  ? 'status-warning dot4' : props.lastData.value4 == 0  ? 'status-safe dot4':""}`}></div>
            <div className={`${props.lastData.value5 == 2 ? 'status-danger dot5' : props.lastData.value5 == 1 ? 'status-warning dot5' : props.lastData.value5 == 0  ? 'status-safe dot5':""}`}></div>
            <div className={`${props.lastData.value6 == 2 ? 'status-danger dot6' : props.lastData.value6 == 1 ? 'status-warning dot6' : props.lastData.value6 == 0  ? 'status-safe dot6':""}`}></div>
            <div className={`${props.lastData.value7 == 2 ? 'status-danger dot7' : props.lastData.value7 == 1 ? 'status-warning dot7' : props.lastData.value7 == 0  ? 'status-safe dot7':""}`}></div>
            <div className={`${props.lastData.value8 == 2 ? 'status-danger dot8' : props.lastData.value8 == 1 ? 'status-warning dot8' : props.lastData.value8 == 0  ? 'status-safe dot8':""}`}></div>
            <div className={`${props.lastData.value9 == 2 ? 'status-danger dot9' : props.lastData.value9 == 1 ? 'status-warning dot9' : props.lastData.value9 == 0  ? 'status-safe dot9':""}`}></div>
            <div className={`${props.lastData.value10 == 2 ? 'status-danger dot10' : props.lastData.value10 == 1 ? 'status-warning dot10' : props.lastData.value10 == 0  ? 'status-safe dot10':""}`}></div>
            <div className={`${props.lastData.value11 == 2 ? 'status-danger dot11' : props.lastData.value11 == 1 ? 'status-warning dot11' : props.lastData.value11 == 0  ? 'status-safe dot11':""}`}></div>
            <div className={`${props.lastData.value12 == 2 ? 'status-danger dot12' : props.lastData.value12 == 1 ? 'status-warning dot12' : props.lastData.value12 == 0  ? 'status-safe dot12':""}`}></div>
            <div className={`${props.lastData.value13 == 2 ? 'status-danger dot13' : props.lastData.value13 >= 1 ? 'status-warning dot13' : props.lastData.value13 == 0  ? 'status-safe dot13':""}`}></div>
            {/* <div className="status-warning dot1"></div> */}
            {/* <div className="status-danger dot2"></div>
  <div className="status-safe dot3"></div> */}
            <img src={Production} />
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveProduction