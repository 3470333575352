import React from 'react'

const Logout = (props) => {
  const goBackToLoginPage = () => {
    props.setIsLoggedIn(true)
    props.setIsLogOut(false)
  }
  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <a href="javascript:;" className="logo-link">
                    <img className="logo-light logo-img logo-img-lg" src="./images/elorca-logo.png" srcset="./images/elorca-logo.png 2x" alt="logo" />
                    <img className="logo-dark logo-img logo-img-lg" src="./images/elorca-logo.png" srcset="./images/elorca-logo.png 2x" alt="logo-dark" />
                  </a>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">You have successfully been logged out.</h4>
                    <div className="nk-block-des text-success">
                      <p>You can now Log in again</p>
                      <button className='btn btn-primary' onClick={goBackToLoginPage}>Login</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                  <div className="row g-3">
                    <div className="col-lg-6">
                      <div className="nk-block-content text-center text-lg-left">
                        <p className="text-soft">&copy; 2022 CryptoLite. All Rights Reserved.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
    </>
  )
}

export default Logout