import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Login from './Components/Authentication/Login'
import Logout from './Components/Authentication/Logout'
import Base from './Components/Base/Base'

// to get Items from secure local storage 
// secure Local storage encrypyt the data and didnot allow anyone to modify data 
const getLocalItems = () => {
  let data = secureLocalStorage.getItem('mattressKey');
  console.log(data);

  if (data) {
    return JSON.parse(secureLocalStorage.getItem('mattressKey'));
  } else {
    return [];
  }
}

const App = () => {
  const [privillegesUrl, setPrivillegesUrl] = useState(getLocalItems())
  console.log(privillegesUrl)
  // Checks status of Login Page by true or false
  const [isLoggedIn,setIsLoggedIn] = useState(true)

  // Checks status of Logout Page by true or false
  const [isLogOut , setIsLogOut] = useState(false)
  console.log(isLogOut)

  // Checks status of Dashboard Page by true or false
  const [isDashboard, setIsDashboard] = useState(false)

  const [apiCredentials, setApiCredentials] = useState([])
  // "getCredintialData" function gives username and password 
  // API call (get method)
  const getCredentialsData = async () => {
    try {
      const url = "https://techwalnutvaishnavi.eldockyard.in/config/";
      // const url = "https://techwalnutvaishnavi.elorca.com/config/";
      const headers = {
        "projectid":"7CAE15D2",
        // "projectid":"F0BAA0E5",
        "nodeid" : "B7424599",
        // "nodeid" : "4ECD6D1F",
        "Content-Type":"application/json",
        "Accept":"*/*"
      }
      const res = await axios.get(url,{headers:headers});
      console.log(res.data);
      setApiCredentials(res.data.config.premium_dashboard)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const mattressPrivillege = secureLocalStorage.setItem('mattressKey', JSON.stringify(privillegesUrl))
    console.log(mattressPrivillege)
   const storedInfo = secureLocalStorage.getItem('isLogIn')
   if(storedInfo === "1"){
    setIsDashboard(true)
    setIsLoggedIn(false)
   }

   getCredentialsData()
  },[privillegesUrl])
  return (
    <>
      <BrowserRouter>
        {isLoggedIn && <Login setIsDashboard={setIsDashboard} 
        setIsLoggedIn={setIsLoggedIn}
        apiCredentials={apiCredentials}
        setPrivillegesUrl={setPrivillegesUrl} />}

        {isDashboard && <Base privillegesUrl={privillegesUrl} setIsDashboard={setIsDashboard} 
        setIsLoggedIn={setIsLoggedIn} 
        setIsLogOut={setIsLogOut} />}

        {isLogOut && <Logout setIsLoggedIn={setIsLoggedIn} setIsLogOut={setIsLogOut} />}
      </BrowserRouter>
    </>
  )
}

export default App