import React from 'react'
import { NavLink } from 'react-router-dom'
import  secureLocalStorage  from  "react-secure-storage";

const Sidebar = (props) => {
  const sidebarMenu = [
    { path_name: "/" , sidebarMenu_name : "Dashboard" },
    { path_name: "/new" , sidebarMenu_name : "New" },
  ]

  return (
    <>
    {
      props.showSidebar === true ? 
        <div className="nk-sidebar nk-sidebar-fixed is-light nk-sidebar-active " data-content="sidebarMenu">
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <a href="javascript:;" className="logo-link nk-sidebar-logo">
              <img className="logo-light logo-img" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo" />
              <img className="logo-dark logo-img" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-dark" />
              <img className="logo-small logo-img logo-img-small" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-small" />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a href="javascript:;" onClick={props.hideSidebar} className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
            {/* <a href="#" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a> */}
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                {/* <!-- .nk-menu-item --> */}
                <li className="nk-menu-item">
                  <NavLink to="/" className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                    <span className="nk-menu-text">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nk-menu-item">
                  <a className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-line-chart"></em></span>
                    <span className="nk-menu-text">Assembly Line</span>
                  </a>
                </li>
                <li className="nk-menu-item">
                  <a className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-alarm"></em></span>
                    <span className="nk-menu-text"> Alarms</span>
                  </a>
                </li>
                <li className="nk-menu-item">
                  <a className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-chevron-up-c"></em></span>
                    <span className="nk-menu-text">Production</span>
                  </a>
                </li>
                {/* <!-- .nk-menu-item --> */}
              </ul>
              {/* <ul className="nk-menu bottom-menu">
                <li className="nk-menu-item" onClick={onLogOutHandle}>
                  <a href='javascript:;' className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-chevron-up-c"></em></span>
                    <span className="nk-menu-text">Log out</span>
                  </a>
                </li>
              </ul> */}
              {/* <!-- .nk-menu --> */}
            </div>
            {/* <!-- .nk-sidebar-menu --> */}
          </div>
          {/* <!-- .nk-sidebar-content --> */}
        </div>
        {/* <!-- .nk-sidebar-element --> */}
      </div> : 
      <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <a href="javascript:;" className="logo-link nk-sidebar-logo">
            <img className="logo-light logo-img" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo" />
            <img className="logo-dark logo-img" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-dark" />
            <img className="logo-small logo-img logo-img-small" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-small" />
          </a>
        </div>
        <div className="nk-menu-trigger me-n2">
          <a href="javascript:;" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
          {/* <a href="#" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a> */}
        </div>
      </div>
      {/* <!-- .nk-sidebar-element --> */}
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              {/* <!-- .nk-menu-item --> */}
              <li className="nk-menu-item">
                <NavLink to="/" className="nk-menu-link">
                  <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                  <span className="nk-menu-text">Dashboard</span>
                </NavLink>
              </li>
              <li className="nk-menu-item">
                <a className="nk-menu-link">
                  <span className="nk-menu-icon"><em className="icon ni ni-line-chart"></em></span>
                  <span className="nk-menu-text">Assembly Line</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a className="nk-menu-link">
                  <span className="nk-menu-icon"><em className="icon ni ni-alarm"></em></span>
                  <span className="nk-menu-text"> Alarms</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a className="nk-menu-link">
                  <span className="nk-menu-icon"><em className="icon ni ni-chevron-up-c"></em></span>
                  <span className="nk-menu-text">Production</span>
                </a>
              </li>
              {/* <!-- .nk-menu-item --> */}
            </ul>
            {/* <ul className="nk-menu bottom-menu">
              <li className="nk-menu-item" onClick={onLogOutHandle}>
                <a href='javascript:;' className="nk-menu-link">
                  <span className="nk-menu-icon"><em className="icon ni ni-chevron-up-c"></em></span>
                  <span className="nk-menu-text">Log out</span>
                </a>
              </li>
            </ul> */}
            {/* <!-- .nk-menu --> */}
          </div>
          {/* <!-- .nk-sidebar-menu --> */}
        </div>
        {/* <!-- .nk-sidebar-content --> */}
      </div>
      {/* <!-- .nk-sidebar-element --> */}
    </div>
}
    </>
  )
}

export default Sidebar