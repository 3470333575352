import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

const Header = (props) => {
  const navigate = useNavigate()
   // Logout Function 
  // the function will removeItem from 'isLogIn' from localStorage and setIsLoggedIn to false 
  // And it gets back us to login page
  const onLogOutHandle =() => {
    secureLocalStorage.removeItem('isLogIn')
    // secureLocalStorage.removeItem('log')
    props.setIsDashboard(false)
    props.setIsLogOut(true)
    navigate('/')
  }
  return (
    <>
      {/* <div className="nk-header nk-header-fixed is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <a href="javascript:;" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="javascript:;" className="logo-link">
                <img className="logo-light logo-img" src="./images/ashtatech.png" srcSet="./images/ashtatech.png 2x" alt="logo" />
                <img className="logo-dark logo-img" src="./images/ashtatech.png" srcSet="./images/ashtatech.png 2x" alt="logo-dark" />
              </a>
            </div> */}
             {/* <!-- .nk-header-brand --> */}
            {/* <div className="nk-header-search ms-3 ms-xl-0">
            <a href="javascript:;" className="logo-link">
                <img className="logo-light logo-img" src="./images/ashtatech.png" srcSet="./images/ashtatech.png 2x" alt="logo" />
                <img className="logo-dark logo-img logo-img-lg" src="./images/ashtatech.png" srcSet="./images/ashtatech.png 2x" alt="logo-dark" />
              </a>
              <div style={{paddingLeft : "60px"}}><h6> Mattress Manufacturing Plant </h6></div>
            </div>
            <div className='fw-bold d-none d-xl-block' >Powered By</div> */}
            {/* <!-- .nk-header-news -->  */}
            {/* <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="dropdown user-dropdown">
                    <div className="user-toggle"> */}
                    {/* FOr desktop Screens */}
                      {/* <div className="user-info d-none d-xl-block">
                        <img className="logo-dark logo-img logo-img-md" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-dark" />
                      </div> */}
                      {/* For small Screens */}
                      {/* <div className="user-info d-block d-sm-none">
                        <img className="logo-dark logo-img logo-img-lg" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-dark" />
                      </div>
                    </div>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <!-- .nk-header-wrap --> */}
        {/* </div> */}
        {/* <!-- .container-fliud --> */}
      {/* </div> */}



      <div className="nk-header nk-header-fixed is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <a href="javascript:;" onClick={props.showSidebarMobile} className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="javascript:;" className="logo-link">
                <img className="logo-light logo-img" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo" />
                <img className="logo-dark logo-img" src="./images/elorca-logo.png" srcSet="./images/elorca-logo.png 2x" alt="logo-dark" />
              </a>
            </div>
            {/* <!-- .nk-header-brand -->
            <div className="nk-header-search ms-3 ms-xl-0">
              <em className="icon ni ni-search"></em>
              <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search anything" />
            </div> */}
            {/* <!-- .nk-header-news --> */}
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="dropdown user-dropdown">
                  <a href="javascript:;" className="dropdown-toggle me-n1" data-bs-toggle="dropdown">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                      <div className="user-info d-none d-xl-block">
                        <div className="user-status user-status-unverified">Verified</div>
                        <div className="user-name dropdown-indicator">{props.privillegesUrl.name}</div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">
                        <div className="user-avatar">
                          <span>VU</span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text"> {props.privillegesUrl.name} </span>
                          <span className="sub-text"> {props.privillegesUrl.username} </span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        {
                          props.privillegesUrl.urls && props.privillegesUrl.urls.map((item, index) => {
                            return <li><NavLink to={item.url_link} target="_blank"><em className="icon ni ni-dashboard"></em><span>{item.url_name}</span></NavLink></li>
                          })
                        }
                        {/* <li><NavLink className="dark-switch" href="javascript:;"><em className="icon ni ni-moon"></em><span>Dark Mode</span></NavLink></li> */}
                      </ul>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li><a href="javascript:;" onClick={onLogOutHandle}><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- .nk-header-wrap --> */}
        </div>
        {/* <!-- .container-fliud --> */}
      </div>
        </>
        )
}

        export default Header