import React from 'react'

const ProductionTable = () => {
  return (
    <>
        <div class="col-lg-12 col-md-12 col-sm-12" style={{marginTop : "30px"}}>
        <div class="card card-full">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title"><span class="me-2">Transaction of Production Supply</span></h6>
              </div>
            </div>
          </div>
          <div class="card-inner p-0 border-top">
            <div class="nk-tb-list nk-tb-orders">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>Batch No.</span></div>
                <div class="nk-tb-col tb-col-sm"><span>Shifting Date</span></div>
                <div class="nk-tb-col tb-col-md"><span>Incharge</span></div>
                <div class="nk-tb-col tb-col-lg"><span>Supplier No.</span></div>
                <div class="nk-tb-col"><span class="d-none d-sm-inline">Status</span></div>
                <div class="nk-tb-col"><span>&nbsp;</span></div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class="tb-lead"><a href="#">1</a></span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">12/01/2023</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col tb-col-md">
                  <span class="tb-sub tb-lead">Aman Gupta</span>
                </div>
                <div class="nk-tb-col tb-col-lg">
                  <span class="tb-sub text-primary">SUP-2309232</span>
                </div>
                <div class="nk-tb-col">
                  <span class="badge badge-dot badge-dot-xs bg-success">Deliver</span>
                </div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class="tb-lead"><a href="#">2</a></span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">02/02/2020</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col tb-col-md">
                  <span class="tb-sub tb-lead">Shantanu Mishra</span>
                </div>
                <div class="nk-tb-col tb-col-lg">
                  <span class="tb-sub text-primary">SUB-2309154</span>
                </div>
                <div class="nk-tb-col">
                  <span class="badge badge-dot badge-dot-xs bg-danger">Canceled</span>
                </div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class="tb-lead"><a href="#">3</a></span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">02/01/2020</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col tb-col-md">
                  <span class="tb-sub tb-lead">Shivani Desai</span>
                </div>
                <div class="nk-tb-col tb-col-lg">
                  <span class="tb-sub text-primary">SUB-2309143</span>
                </div>
                <div class="nk-tb-col">
                  <span class="badge badge-dot badge-dot-xs bg-success">Deliver</span>
                </div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class="tb-lead"><a href="#">4</a></span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">01/29/2020</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col tb-col-md">
                  <span class="tb-sub tb-lead">Anjali Sharma</span>
                </div>
                <div class="nk-tb-col tb-col-lg">
                  <span class="tb-sub text-primary">SUB-2305684</span>
                </div>
                <div class="nk-tb-col">
                  <span class="badge badge-dot badge-dot-xs bg-success">Deliver</span>
                </div>
              </div>
              <div class="nk-tb-item">
                <div class="nk-tb-col">
                  <span class="tb-lead"><a href="#">5</a></span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">01/29/2020</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col tb-col-md">
                  <span class="tb-sub tb-lead">Darshan Mehta</span>
                </div>
                <div class="nk-tb-col tb-col-lg">
                  <span class="tb-sub text-primary">SUB-2305564</span>
                </div>
                <div class="nk-tb-col">
                  <span class="badge badge-dot badge-dot-xs bg-warning">Due</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-inner-sm border-top text-center d-sm-none">
            <a href="#" class="btn btn-link btn-block">See History</a>
          </div>
        </div>
        {/* <!-- .card --> */}
      </div>
    </>
  )
}

export default ProductionTable