import React from 'react'

const TargetSummary = () => {
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12" style={{marginTop:"30px"}}>
        <div className="card">
          <div className="card-inner">
            <div className="card-title-group align-start mb-2">
              <div className="card-title">
                <h6 className="title">Month Production</h6>
                <p>In last 30 days Production of Hot melt gluing station</p>
              </div>
            </div>
            <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
              <div className="nk-sale-data-group flex-md-nowrap g-4">
                <div className="nk-sale-data">
                  <span className="amount">14,299.59 <span className="change down text-danger"><em className="icon ni ni-arrow-long-down"></em>16.93%</span></span>
                  <span className="sub-title">This Month</span>
                </div>
                <div className="nk-sale-data">
                  <span className="amount">7,299.59 <span className="change up text-success"><em className="icon ni ni-arrow-long-up"></em>4.26%</span></span>
                  <span className="sub-title">This Week</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- .col --> */}
    </>
  )
}

export default TargetSummary