import React from 'react'

const MachineList = () => {
  return (
    <>
        <div className="card card-full" style={{ marginTop: "30px"}}>
          <div className="card-inner">
            <div className="card-title-group">
              <div className="card-title">
                <h6 className="title"><span className="me-2">Today's Machine Alerts</span></h6>
              </div>
            </div>
          </div>
          <div className="card-inner p-0 border-top">
            <div className="nk-tb-list nk-tb-orders">
              <div className="nk-tb-item nk-tb-head">
                <div className="nk-tb-col tb-lead"><span>Alerts</span></div>
                <div className="nk-tb-col tb-col-sm tb-lead"><span>Station Name</span></div>
                <div className="nk-tb-col tb-col-md tb-lead"><span>Timing</span></div>
                {/* <div className="nk-tb-col tb-col-lg"><span>Supplier No.</span></div>
                <div className="nk-tb-col"><span className="d-none d-sm-inline">Status</span></div>
                <div className="nk-tb-col"><span>&nbsp;</span></div> */}
              </div>
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead text-danger fw-bold">Red Alert</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <div className="user-card">
                    <div className="user-name">
                      <span className="tb-lead">Station 2</span>
                    </div>
                  </div>
                </div>
                <div className="nk-tb-col tb-col-md">
                  <span className="tb-sub tb-lead">03:15 pm</span>
                </div>
              </div>
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead text-danger fw-bold">Red Alert</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <div className="user-card">
                    <div className="user-name">
                      <span className="tb-lead">Station 1</span>
                    </div>
                  </div>
                </div>
                <div className="nk-tb-col tb-col-md">
                  <span className="tb-sub tb-lead">4:30 pm</span>
                </div>
              </div>
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead text-warning fw-bold">Warning Alert</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <div className="user-card">
                    <div className="user-name">
                      <span className="tb-lead">Station 1</span>
                    </div>
                  </div>
                </div>
                <div className="nk-tb-col tb-col-md">
                  <span className="tb-sub tb-lead">3:55 pm</span>
                </div>
              </div>
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead text-warning fw-bold">Warning Alert</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <div className="user-card">
                    <div className="user-name">
                      <span className="tb-lead">Station 1</span>
                    </div>
                  </div>
                </div>
                <div className="nk-tb-col tb-col-md">
                  <span className="tb-sub tb-lead">4:15 pm</span>
                </div>
              </div>
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead text-warning fw-bold">Warning Alert</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <div className="user-card">
                    <div className="user-name">
                      <span className="tb-lead">Station 4</span>
                    </div>
                  </div>
                </div>
                <div className="nk-tb-col tb-col-md">
                  <span className="tb-sub tb-lead">3:05 pm</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default MachineList