import React from 'react'
import DailyProduction from '../../Charts/DailyProduction'
import DetailsTargets from './DetailsTargets'
import ProductionTable from './ProductionTable'

const ProductionUpdates = () => {
  return (
    <>
      <div class="nk-block-head nk-block-head-sm">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">Overview/ Summary</h3>
          </div>
          {/* <!-- .nk-block-head-content --> */}
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>
      {/* <!-- .nk-block-head --> */}
      <div className='row'>
        <div className='col'>
          <DailyProduction />
        </div>
      </div>
      <div className='row'>
        <DetailsTargets />
      </div>
      <div className='row'>
        <ProductionTable />
      </div>
    </>
  )
}

export default ProductionUpdates