import React, { useState } from 'react'
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);

// Option to present how the chart will present 
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
};

const DailyProduction = () => {
  const [select_frame, setselect_frame] = useState("Daily");
  // Onchange function to get the time-frame onchange value
  const updateValue = (value) => {
    setselect_frame(value)
  }
  const buttons = ["Daily", "Weekly", "Monthly"]

  const data = {
    labels : ["x","y","z"],
    // [city name in array => jaipur , delhi , pune ....]
    datasets : [{
        fill: true,  
        label : "",
        data : [11,45,34],
        // [max temp in array => 28 , 30 , 29...]
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }]
}
console.log(data)
  return (
    <>
    <div className="card">
        <div className='card-inner'>
          <div className='d-flex justify-content-between'>
            <h5>Production Chart</h5>
            <div className='btn'>
              {buttons.map((btn) => {
                return <input type="button" className={btn === select_frame ? 'btn-value' : 'btn-val'} value={btn} onClick={() => updateValue(btn)} />
              })}
            </div>
          </div>
          <div style={{height : 440, marginBottom : "50px"}} >
          <Line options={options} data={data} />
        </div>
        </div>
      </div>
        {/* <div style={{width : 700, marginBottom : "50px"}} >
        <Bar data={data} options={options} />
        </div> */}
    </>
  )
}

export default DailyProduction