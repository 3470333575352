import React, { useState } from 'react'

const DetailsTargets = () => {
  
  return (
    <>
      <div class="col-lg-6 col-md-6 col-sm-12" style={{marginTop : "30px"}}>
        <div class="card card-orders h-100">
          <div class="nk-ecwg nk-ecwg1">
            <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title">
                  <h6 class="title">Total Producation Sales</h6>
                </div>
                <div class="card-tools">
                  <a href="javascript:;" class="link">View Report</a>
                </div>
              </div>
              <div class="data">
                <div class="amount">3,45,000</div>
                <div class="info"><strong>39,537</strong> in last month</div>
              </div>
              <div class="data">
                <h6 class="sub-title">This week so far</h6>
                <div class="data-group">
                  <div class="amount">23872</div>
                  <div class="info text-end"><span class="change up text-danger"><em class="icon ni ni-arrow-long-up"></em>4.63%</span><br/><span>vs. last week</span></div>
                </div>
              </div>
            </div>
            {/* <!-- .card-inner --> */}
          </div>
          {/* <!-- .nk-ecwg --> */}
        </div>
        {/* <!-- .card --> */}
      </div>


      <div class="col-lg-6 col-md-6 col-sm-12" style={{marginTop : "30px"}}>
        <div class="card h-100">
          <div class="nk-ecwg nk-ecwg2">
            <div class="card-inner">
              <div class="card-title-group mt-n1">
                <div class="card-title">
                  <h6 class="title">Average order</h6>
                </div>
                <div class="card-tools me-n1">
                  <div class="dropdown">
                    <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                      <ul class="link-list-opt no-bdr">
                        <li><a href="#" class="active"><span>15 Days</span></a></li>
                        <li><a href="#"><span>30 Days</span></a></li>
                        <li><a href="#"><span>3 Months</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="data">
                <div class="data-group">
                  <div class="amount">463,35</div>
                  <div class="info text-end"><span class="change up text-danger"><em class="icon ni ni-arrow-long-up"></em>4.63%</span><br /><span>vs. last week</span></div>
                </div>
              </div>
              <h6 class="sub-title">Orders over time</h6>
            </div>
            {/* <!-- .card-inner --> */}
          </div>
          {/* <!-- .nk-ecwg --> */}
        </div>
        {/* <!-- .card --> */}
      </div>
    </>
  )
}

export default DetailsTargets