import React from 'react'

const Preventive = () => {
  //Create object here for data
  const preventive_notifications = [
    {cur_date:"02 March 2023", machine_name : "Seving Machine Noise ",notification_header: "Tape edge machine no.2",notification_message:"Server will be upgraded and thus there will be no connection to S2"},
    {cur_date:"01 March 2023", machine_name : "3rd Belt Changeover",notification_header: "Cross conveyor no. 3",notification_message:"Server will be upgraded and thus there will be no connection to S2"}
  
  ]

  return (
    <>
      <div class="col-lg-6 col-md-6 col-sm-12" style={{marginTop : "30px"}}>
        <div class="card h-100">
          <div class="card-inner border-bottom">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Preventive Maintainance </h6>
              </div>
              <div class="card-tools">
                <a href="javascript:;" class="link">View All</a>
              </div>
            </div>
          </div>
          <div class="card-inner">
            <div class="timeline">
                <h6 class="timeline-head">March 2022</h6>
                <ul class="timeline-list">
            {
              preventive_notifications.map((item,index) => {
                return(
                
                  <li class="timeline-item">
                    <div class="timeline-status bg-primary is-outline"></div>
                    <div class="timeline-date">{item.cur_date}<em class="icon ni ni-alarm-alt"></em></div>
                    <div class="timeline-data">
                      <h6 class="timeline-title">{item.notification_header}</h6>
                      <div class="timeline-des">
                        <p>{item.machine_name}</p>
                        {/* <span class="time">{item.notification_message}</span> */}
                      </div>
                    </div>
                  </li>
                  
                

                )
              })
            }
          
            
          </ul>
              </div>
          
          </div>
        </div>
        {/* <!-- .card --> */}
      </div>
    </>
  )
}

export default Preventive