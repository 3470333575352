import React, { useState } from 'react'
import Content from './Content'
import Sidebar from './Sidebar'
import Header from './Header'

const Base = (props) => {
  const [showSidebar ,setShowSidebar] = useState(false)
  const showSidebarMobile = () => {
    setShowSidebar(true)
  }
  const hideSidebar = () => {
    setShowSidebar(false)
  }
  return (
    <>
        <div class="nk-app-root">
        {/* <!-- main @s --> */}
        <div class="nk-main ">
            {/* <!-- sidebar @s --> */}
             <Sidebar showSidebar={showSidebar} hideSidebar={hideSidebar} />
            {/* <!-- sidebar @e --> */}
            {/* <!-- wrap @s --> */}
            <div class="nk-wrap ">
                {/* <!-- main header @s --> */}
                <Header showSidebarMobile={showSidebarMobile} privillegesUrl={props.privillegesUrl} setIsDashboard={props.setIsDashboard} setIsLoggedIn={props.setIsLoggedIn} setIsLogOut={props.setIsLogOut} />
                {/* <!-- main header @e --> */}
                {/* <!-- content @s --> */}
                <Content />
                {/* <!-- content @e --> */}
                {/* <!-- footer @s --> */}
                {/* <!-- footer @e --> */}
            </div>
            {/* <!-- wrap @e --> */}
        </div>
        {/* <!-- main @e --> */}
    </div>
    {/* <!-- app-root @e --> */}
    </>
  )
}

export default Base