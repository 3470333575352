import React from 'react'
import { useForm } from "react-hook-form";
import  secureLocalStorage  from  "react-secure-storage";
import swal from 'sweetalert';
import Footer from '../Base/Footer';

const Login = (props) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    console.log(data)
    const loginDetails = {
      username : data.username,
      password : data.password
    }
    if(props.apiCredentials.find(api => api.username === data.username) && props.apiCredentials.find(api => api.password === data.password)) {
        const getFilterData = props.apiCredentials.find((api) => {
          return api.username === data.username
        })
        console.log(getFilterData)
        props.setPrivillegesUrl(getFilterData)
        props.setIsLoggedIn(false)
        props.setIsDashboard(true)
        secureLocalStorage.setItem('isLogIn', "1")
      }
      else {
        swal("You have entered an invalid username or password");
      }
  }
  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <a href="javascript:;" className="logo-link">
                    <img className="logo-light logo-img logo-img-lg" src="./images/elorca-logo.png" srcset="./images/elorca-logo.png 2x" alt="logo" />
                    <img className="logo-dark logo-img logo-img-lg" src="./images/elorca-logo.png" srcset="./images/elorca-logo.png 2x" alt="logo-dark" />
                  </a>
                </div>
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Sign-In</h4>
                        <div className="nk-block-des">
                          <p>Get onboard with Elorca Mattress Platform</p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" for="default-01">Email</label>
                        </div>
                        <div className="form-control-wrap">
                          <input type="text" className="form-control form-control-lg" id="default-01" placeholder="Enter your email address or username" defaultValue="dashboard_premium_trial@techwalnut.com"  {...register("username", { required: true })} />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" for="password">Password</label>
                          <a className="link link-primary link-sm" href="javascript:;">Forgot Code?</a>
                        </div>
                        <div className="form-control-wrap">
                          <a href="javascript:;" className="form-icon form-icon-right passcode-switch lg" data-target="password">
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input type="password" className="form-control form-control-lg" id="password" defaultValue="dashboard_premium_trial_password"  placeholder="Enter your passcode" {...register("password", { required: true })} />
                        </div>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-lg btn-primary btn-block">Sign in</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
    </>
  )
}

export default Login