import React from 'react'

const CardAlarm = (props) => {
  return (
    <>
      <div className='col-lg-3 col-md-6 col-sm-12' style={{ marginTop : "20px"}}>
        <div className='card card_style'>
          <div className='d-flex total_employee_style align-items-center'>
            {/* <div className='img_icon card_1_color'>
              <img className='img-fluid' src={Alert_icon} alt="" />
            </div> */}
            <div className='card_desc'>
              <h6>Total Target</h6>
              <h5>1500</h5>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12' style={{ marginTop : "20px"}}>
        <div className='card card_style'>
          <div className='small-screen d-flex total_employee_style align-items-center'>
            {/* <div className='img_icon card_2_color'>
              <img className='img-fluid' src={Warning_icon} alt="" />
            </div> */}
            <div className='card_desc'>
              <h6>Target Achieve</h6>
              <h5>1100</h5>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12' style={{ marginTop : "20px"}}>
        <div className='card card_style'>
          <div className='medium-screen d-flex total_employee_style align-items-center'>
            {/* <div className='img_icon card_3_color'>
              <img className='img-fluid' src={Alarm_icon} alt="" />
            </div> */}
            <div className='card_desc'>
              <h6>Remaining</h6>
              <h5>290</h5>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12' style={{ marginTop : "20px"}}>
        <div className='card card_style'>
          <div className='freelancing-screen d-flex total_employee_style align-items-center'>
            {/* <div className='img_icon card_6_color'>
              <img className='img-fluid' src={Report_icon} alt="" />
            </div> */}
            <div className='card_desc'>
              <h6>Scrape</h6>
              <h5>110</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardAlarm