import React from 'react'

const Footer = () => {
    const currDate = new Date().getFullYear
  return (
    <>
        <div class="nk-footer nk-footer-fluid bg-lighter">
                <div class="container-xl">
                    <div class="nk-footer-wrap">
                        <div class="nk-footer-copyright"> &copy; {currDate} Elorca. Project by <a href="javascript:;" target="_blank">Techwalnut Innovations</a>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Footer