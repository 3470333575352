import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import Production from '../Pages/Production/Production';
import ProductionUpdates from '../Pages/ProductionDetails/ProductionUpdates';
import AssemblyLine from '../Pages/AssemblyLine/AssemblyLine'
import Alarms from '../Pages/Alarms/Alarms'
import axios from 'axios';

const Content = () => {
  // Raw Data Stored in getFetchData as value1 , value2, value3
  const [m1ProductionData, setM1ProductionData] = useState([]);
  console.log(m1ProductionData);

  const [lastData, setLastData] = useState([]);
  console.log(lastData);

  // var datas = {
  //   "details": {
  //     "points": 50,
  //     "interval": "M",
  //     "values": [
  //       {
  //         "label": "M1",
  //         "start": 25,
  //         "end": 60
  //       }, {
  //         "label": "M2",
  //         "start": 20,
  //         "end": 60
  //       }, {
  //         "label": "M3",
  //         "start": 20,
  //         "end": 50
  //       }, {
  //         "label": "M4",
  //         "start": 25,
  //         "end": 60
  //       }, {
  //         "label": "M5",
  //         "start": 20,
  //         "end": 60
  //       }, {
  //         "label": "M6",
  //         "start": 20,
  //         "end": 50
  //       }, {
  //         "label": "M7",
  //         "start": 25,
  //         "end": 60
  //       }, {
  //         "label": "M8",
  //         "start": 20,
  //         "end": 60
  //       }, {
  //         "label": "M9",
  //         "start": 20,
  //         "end": 50
  //       }, {
  //         "label": "M10",
  //         "start": 25,
  //         "end": 60
  //       }, {
  //         "label": "C1",
  //         "start": 25,
  //         "end": 60
  //       }, {
  //         "label": "C2",
  //         "start": 20,
  //         "end": 60
  //       }, {
  //         "label": "C3",
  //         "start": 20,
  //         "end": 50
  //       }
  //     ]
  //   },
  //   "dataname": "Production-Machine-logger"
  // }

  // var config = {
  //   method: 'post',
  //   url: 'https://techwalnutvaishnavi.elorca.com/random-data/',
  //   headers: {
  //     // 'key': `{${props.item.key}}`, 
  //     'key': "18FDFCEA_2",
  //     'Content-Type': 'application/json'
  //   },
  //   data: datas
  // };

  var config = {
    method: 'get',
    url: 'https://siddhesh.elorca.com/40-sensor-logger/?page=1&?records=1',
    headers: { 
      'projectid': 'A12740A0'
    }
  };
  
  const getProductionData = async () => {
    console.log("HII")
    try {
      // setInterval(async () => {
        const res = await axios(config);
        setLastData(res.data.data.findLast((element) => element))
        setM1ProductionData(res.data.data)
        console.log(res.data);
      // }, 5000)
    }
    catch (error) {
      console.log(error);
    }
  }

  // Set the interval for the API call (e.g., every 5 seconds)
  const intervalTime = 10000; // 5 seconds in milliseconds
  useEffect(() => {
    const intervalId = setInterval(getProductionData, intervalTime);

    // To stop the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
    // getProductionData();
  }, [])

  // const [count_danger,setCount_danger] = useState(0)
  // const [count_warn,setCount_warn] = useState(0)
  // const [count_safe,setCount_safe] = useState(0)

  // useEffect(() => {
  //     if(lastData.M1 >= 50){
  //         setCount_danger(count_danger + 1)
  //     }
  //     if(lastData.M1 >= 35 && lastData.M1 <=49){
  //       setCount_warn(count_warn + 1)
  //   }
  //   if(lastData.M1 <= 34){
  //       setCount_safe(count_safe + 1)
  //   }
  // },[lastData])
  return (
    <>
        <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
            <Routes>
                <Route path='/' element={<Production lastData={lastData} />} />
                <Route path='/assemblyline' element={<AssemblyLine lastData={lastData} />} />
                <Route path='/alarms' element={<Alarms />} />
                <Route path='/production' element={<ProductionUpdates />} />
            </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content