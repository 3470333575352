import React from 'react'
import { Pie } from 'react-chartjs-2'
// IMP Elements require to implement charts.js
import { Chart as ChartJS ,ArcElement, Tooltip, Legend } from 'chart.js'
ChartJS.register(ArcElement, Tooltip, Legend)

// Option to present how the chart will present 
export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right"
    },
    },
};

const TotalProduction = () => {
    const data = {
        labels : ["ACTUAL", "REMAINING", "SCRAPE"] ,
        // [State name in array => AP , karnatak , haryana ....]
        datasets : [{
            data : [1100 , 290, 110] ,
            // [deaths counts in array =>  745674, 758758, 758888...]
            backgroundColor : [
                "#EC6B56", "#FFC154", "#47B39C"
            ],
            borderColor : [
                "#EC6B56", "#FFC154", "#47B39C"
            ],
            borderWidth : 1,
            width : 500,

        }]
    };
  return (
      <>
        <div className='card' style={{height:"413px"}}>
        <div className='card-inner'>
        <div className='title'>
            <h4>Total Productivity Targets : 1500</h4>
        </div>
        <div style={{ width : 290}}>
        <Pie data={data} options={options} />
        </div>
        </div>
        
        </div>
      </>
    
  )
}

export default TotalProduction